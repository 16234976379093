import { createAction, props } from '@ngrx/store';
import { AuthUser } from '@core/states/auth/interfaces/auth-user';
import { LoadStatus } from '@core/states/auth/interfaces/load-status';

export const login = createAction(
  '[Auth] Login',
  props<{ email: string; password: string; rememberMe?: boolean }>()
);
export const logout = createAction('[Auth] Logout');
export const removeUser = createAction('[Auth] Remove User');
export const updatePassword = createAction(
  '[Auth] Update Password',
  props<{ oldPassword: string; newPassword: string }>()
);
export const updateUser = createAction(
  '[Auth] Update User',
  props<{ user: AuthUser }>()
);
export const setUser = createAction(
  '[Auth] Set User',
  props<{ user: AuthUser }>()
);
export const setError = createAction(
  '[Auth] Set Error',
  props<{ error: Error }>()
);
export const setLoadStatus = createAction(
  '[Auth] Set Loading',
  props<{ loadStatus: LoadStatus }>()
);
