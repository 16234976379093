export type PasswordRuleErrors = {
  hasMissingSpecialCharacter: boolean;
  hasMissingNumber: boolean;
  hasMissingUpperLetter: boolean;
  hasMissingCharacterLength: boolean;
};

export function fulfillsPasswordRules(password: string): boolean {
  if (!password) {
    return false;
  }
  return Object.values(checkPasswordRuleErrors(password)).every(
    value => !value
  );
}
export function checkPasswordRuleErrors(password: string): PasswordRuleErrors {
  return {
    hasMissingSpecialCharacter: !hasSpecialCharacter(password),
    hasMissingNumber: !hasNumber(password),
    hasMissingUpperLetter: !hasUpperLetter(password),
    hasMissingCharacterLength: !hasMinCharacterLength(password),
  };
}

function hasSpecialCharacter(password: string): boolean {
  return password.length >= 1 && new RegExp(`.*[^a-zA-Z0-9].*`).test(password);
}

function hasNumber(password: string): boolean {
  return password.length >= 1 && new RegExp(`.*[0-9].*`).test(password);
}

function hasUpperLetter(password: string): boolean {
  return password.length >= 1 && new RegExp(`.*[A-Z].*`).test(password);
}

function hasMinCharacterLength(password: string): boolean {
  return password.length >= 8;
}
