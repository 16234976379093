/**
 * Represents loading status of authenticated user
 *
 * NOT_LOADED = Initial value; user has not been loaded
 * LOADING = User will be fetched from backend e.g. during login
 * UPDATING = User will be updated e.g. during password changes, user data changes
 * DATA = Data has been fetched from backend
 * ERROR = Error has been occurred
 */
export enum LoadStatus {
  NOT_LOADED,
  LOADING,
  UPDATING,
  DATA,
  ERROR,
}
