import {
  checkPasswordRuleErrors,
  PasswordRuleErrors,
} from '@core/helpers/password-rules/password-rules';
import { AbstractControl } from '@angular/forms';

export function PasswordRulesValidator(control: AbstractControl<string>) {
  const ruleErrors = checkPasswordRuleErrors(control.value);

  // Filter out keys with falsy values (false or undefined)
  const validRules = Object.keys(ruleErrors).filter(
    key => ruleErrors[key as keyof PasswordRuleErrors]
  );

  // Return null if there are no valid rules, otherwise return the valid rules
  const response = validRules.length
    ? Object.fromEntries(validRules.map(key => [key, true]))
    : null;

  return response;
}
